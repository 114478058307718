// *****************************************
// GENERAL VARIABLES
$item__global: #206feb;
$item__info: #05a5cf;
$item__warning: #f39530;
$item__error: #f26d83;
$item__success: #38ac68;
//
$item__info__background: #e6f7ff;
$item__warning__background: #fffbe6;
$item__error__background: #fef2f0;
$item__success__background: #f6ffed;
//
$item__inactive: #e5e5e5;
$item__inactive__dark: gray;
$item__inactive__light: #f0f2f5;
//
$item__icon__1: #818181;
$item__title: #575757;
$item__title__2: #42adce;

// *****************************************
// THEME
$item__gray__background: #f0f2f5;
$item__dark__background: #202020;
