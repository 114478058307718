@import '~bootstrap/scss/bootstrap';
@import './variables.scss';
@import './buttonsStyle.scss';
@import './layout.scss';
@import './drawer.scss';
@import './loginStyles.scss';

.generalStyles__flex {
  display: flex;
}

.generalStyles__flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.generalStyles__flexEnd {
  display: flex;
  justify-content: flex-end;
}

.generalStyles__spaceBetween {
  display: flex;
  justify-content: space-between;
}

.generalStyles__decorationNone {
  text-decoration: none;
}

.generalStyles__hoverCursor {
  cursor: pointer;
}

// ******************************************************************
// MIXINGS
// ******************************************************************
// GENRAL
.generalStyles__info {
  color: $item__info !important;
}
.generalStyles__error {
  color: $item__error !important;
}
.generalStyles__warning {
  color: $item__warning !important;
}
.generalStyles__success {
  color: $item__success !important;
}
// ******************************************************************
.swalStyle__box {
  position: relative;
  width: 100%;
}
.position-absolute {
  position: absolute;
}
.generalStyles__deleteItemIcon {
  font-size: 50px;
}
.generalStyles__modal1 {
  width: 50% !important;
}
.generalStyles__tableActionBox {
  width: 150px;
}
.generalStyles__sunIcon {
  color: #da922e;
  font-size: 20px;
  margin-left: 10px;
}
.generalStyles__moonIcon {
  color: silver;
  font-size: 20px;
  margin-left: 10px;
}
// ******************************************************************
// NOTIFICATIONS
.generalStyles__notificationSuccess {
  background-color: $item__success__background !important;
  color: $item__success !important;
}
.generalStyles__notificationInfo {
  background-color: $item__info__background !important;
  color: $item__info !important;
}
.generalStyles__notificationError {
  background-color: $item__error__background !important;
  color: $item__error !important;
}
.generalStyles__notificationWarning {
  background-color: $item__warning__background !important;
  color: $item__warning !important;
}
.ant-notification-notice-message {
  color: black !important;
}

// ******************************************************************
// APPEARANCE
.settings__cardImage {
  width: 100px;
  height: 60px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 20px;
  border-radius: 10px;
}
.settings__themeTitle {
  font-size: 11px !important;
}
.settings__activeBox {
  height: 20px;
  width: 20px;
  font-size: 10px;
  color: $item__info;
  text-align: center;
  margin-top: 12px;
  margin-right: 5px;
}
.settings__activeBox__light {
  background-color: $item__gray__background;
}
.settings__activeBox__dark {
  background-color: black;
}
.settings__activeCard {
  width: 140px;
  border-radius: 5px;
  border: solid 1px $item__info !important;
  margin: 10px;
}
.settings__card {
  width: 140px;
  border-radius: 5px;
  margin: 10px;
}
.settings__sunIcon {
  color: rgb(255, 174, 0);
  margin-right: 5px;
}
.settings__moonIcon {
  color: rgb(149, 194, 212);
}
.settings__titleIcon {
  color: $item__global;
}
